import React from "react";
import { FormProvider, useForm } from "react-hook-form";
import {  useUpdateDigitalCommunication, NEW_UpdateDigitalCommunication} from "../DocumentsSection/GrunndataDocumentsSection.queries";
import { ReservationDigitalCommunication } from "./ReservationDigitalCommunication";
import DigitalCommunicationToast_NEW from "./DigitalCommunicationToast_NEW";


export type ReservationFormData = {
    reservation: boolean;
    email: string;
};

const toSubmitData = (formData: NEW_UpdateDigitalCommunication) => ({
    reservation: formData.reservation || false,
    email: formData.email || ""
});

interface Props {
    isRadgiver: boolean;
    isDNB: boolean;
    formInfo: ReservationFormData;
}

export const ReservationFormDigitalCommunicationNew: React.FC<Props> = ({ formInfo, isRadgiver }: Props) => {
    const form = useForm<ReservationFormData>({
        defaultValues: {
            reservation: formInfo.reservation,
            email: formInfo.email
        },
        shouldFocusError: false
    });

    const { handleSubmit } = form;
    
    const mutation = useUpdateDigitalCommunication();

    const initialEmail = formInfo.email;
    
    const onSubmit = (formData: NEW_UpdateDigitalCommunication) => {
        mutation.reset();
        mutation.mutate(toSubmitData(formData), {
        });
    };

    return (
        <FormProvider {...form}>
            <form className={"reservation"} onSubmit={handleSubmit(onSubmit)}>
                <ReservationDigitalCommunication initialEmail={initialEmail}  />
                <DigitalCommunicationToast_NEW isRadgiver={isRadgiver} mutation={mutation} />
            </form>
        </FormProvider>
    );
};

