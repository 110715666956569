import React from "react";

import { Flag } from "./featureflag.model";
import { useFeatureflag } from "./useFeatureflag";

interface Props {
    flag: Flag;
}

export const Featureflag: React.FC<React.PropsWithChildren<Props>> = ({ flag: featureflag, children }) => {
    const isEnabled = useFeatureflag(featureflag);

    if (!isEnabled) {
        return null;
    }

    return <>{children}</>;
};
