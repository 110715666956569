import { useEffect } from "react";

import { Trackingkey, track } from "src/tracking";

import { useAgreementList } from "@features/agreements/agreements.queries";
import { AvtaleProduktID } from "@features/agreements/avtale.model";
import { HomePageOffers } from "@features/kjop/components/HomePageOffers";
import { isDateWithinRange } from "@features/tasks/tasks.utils";
import { TaskResponseDto } from "@model/gen/models/TaskResponseDto";

import { TaskCard } from "../TaskCard/TaskCard";
import { GetLocalTasks, LocalTaskProps } from "./LocalTasks";

interface TaskListProps {
    heading?: React.ReactNode;
    className?: string;
    tasks: TaskResponseDto[];
    category?: TaskResponseDto["category"];
    status?: TaskResponseDto["status"][];
    productCode?: string;
    agreementId?: string;
    sort?: {
        order: "asc" | "desc";
        sortBy: "createdDate" | "priority" | "title" | "status";
    };
    showImages?: boolean;
    showOffers?: boolean;
}

export const TaskList = (props: TaskListProps) => {
    const agreements = useAgreementList();

    // kunden har forsikringer i “Ansatte“-kategorien (med unntak av yrkesskade)
    const hasWorkerAgreements = agreements.data?.agreements.some(
        (avtale) => avtale.category === "ANSATT" && avtale.productCode !== AvtaleProduktID.YRKESSKADE
    );

    const { category, status, tasks = [], sort, heading, className } = props;

    const showImage = props.showImages || false;
    const showOffers = props.showOffers || false;

    // Check if there are tasks with subjects "Fornyelse" so that localtask about renewal can be added
    const showFornyelsesTask = tasks.some((task) => task.subjects?.includes("Fornyelse"));

    const localTasks = GetLocalTasks(agreements.data?.agreements, showFornyelsesTask);

    // Remove tasks from datamodeller with subjects "Fornyelse"
    let tasksToShow = tasks.filter((task) => !task.subjects?.includes("Fornyelse"));

    useEffect(() => {
        track({
            hendelse: Trackingkey.SeListe,
            type: `varsler ${category ? `i kategorien ${category}` : ""}`,
            antall: tasksToShow?.length ?? 0
        });
    }, [tasksToShow, category, hasWorkerAgreements]);

    const updateTasksToShow = (localTask: TaskResponseDto & LocalTaskProps) => {
        if (localTask && isDateWithinRange(localTask.fromDate, localTask.toDate)) {
            tasksToShow = [...tasksToShow, localTask];
        }
        return tasksToShow;
    };

    // Add all localTasks and add all tasks with "shouldShow" set to true to tasksToShow
    Object.values(localTasks).forEach((task) => {
        if (task.shouldShow) {
            updateTasksToShow(task);
        }
    });

    const filteredTasks = tasksToShow?.filter(
        (task: TaskResponseDto & LocalTaskProps) =>
            (category ? task.category?.toLowerCase() === category.toLowerCase() : true) &&
            (status ? status?.includes(task.status) : task.status?.includes("sendt") || task.status?.includes("lest"))
    );

    if (!filteredTasks?.length) {
        return showOffers ? <HomePageOffers homepage /> : null;
    }

    if (sort) {
        filteredTasks.sort((a, b) => {
            // sort by createdDate desc by default
            if (sort.sortBy === "createdDate") {
                return new Date(a.createdDate ?? "") < new Date(b.createdDate ?? "") ? 1 : -1;
            }
            if (sort.order === "asc") {
                return (a[sort.sortBy] ?? "") > (b[sort.sortBy] ?? "") ? 1 : -1;
            } else {
                return (a[sort.sortBy] ?? "") < (b[sort.sortBy] ?? "") ? 1 : -1;
            }
        });
    }

    return (
        <section className={`${className ? className : "varslinger"}`}>
            {heading && heading}
            <ul>
                {filteredTasks?.map((task) => (
                    <li key={task.taskId}>
                        <TaskCard task={task} {...{ showImage }} />
                    </li>
                ))}
                {showOffers && <HomePageOffers list />}
            </ul>
        </section>
    );
};
