import { useContext } from "react";

import { Flag, useFeatureflag } from "src/common/featureflag";
import { isRadgiver } from "src/common/rolle";
import ThemeContext from "src/context/ThemeContext";
import { Distributor } from "src/model/theme.typer";

import { Side, SideHeader, SideInnhold } from "@components/Side";
import { useBruker } from "@features/bruker/queries";
import { ErrorMessageBox } from "@fremtind/jkl-message-box-react";
import { ConsentForm } from "../ConsentForm";
import { ConsentFormNew } from "../ConsentForm/ConsentFormNew";
import { useReservation, useDatasharing_NEW} from "../consent.queries";
import "./Reservation.scss";
import { ReservationLoader } from "./ConsentLoader";


export default function Consent() {
    const shouldUseNewService = useFeatureflag(Flag.NEW_CONSENT);

    const { bruker } = useBruker();
    const { distributor } = useContext(ThemeContext);
    const query = useReservation();
    const query__NEW = useDatasharing_NEW();

    if (!bruker) return null;

    const dataSharing = !shouldUseNewService ? query.data?.dataSharing || false :  query__NEW.consent.data?.dataSharing || false;
   
    const reservation = !query.data?.consent.consent || false;

    const email =  query.data?.consent.email || "";

    const isDNB = distributor === Distributor.DNB;

    const hasAccess =  shouldUseNewService ? bruker?.dynamicMenu?.consent?.showMenuItem : true;

    return (
        <Side compact>
            <SideHeader title={bruker?.valgtOrganisasjon?.navn ?? "Deling av data"}/>

            {!shouldUseNewService && hasAccess &&  (
                <SideInnhold loadingText="Laster inn samtykke" query={query} disableLoader={true}>
                    {query.isLoading && <ReservationLoader />}
                    {query.isError && <ErrorMessageBox>Klarte ikke hente informasjon om samtykke.</ErrorMessageBox>}
                    {query.data && (
                        <ConsentForm
                            isRadgiver={isRadgiver(bruker)}
                            isDNB={isDNB}
                            formInfo={{ reservation, dataSharing, email }}
                        />
                    )}
                </SideInnhold>
            )}

            {shouldUseNewService && hasAccess &&  (
                <SideInnhold loadingText="Laster inn samtykke" query={query__NEW.consent} disableLoader={true}>
                    <div className="flex flex-col gap-10">
                    {( query__NEW.consent.isLoading) && <ReservationLoader />}
                    {( query__NEW.consent.isError) && !isDNB && (
                        <ErrorMessageBox>Klarte ikke hente informasjon om reservasjon.</ErrorMessageBox>
                    )}

                    {query__NEW.consent.data && (
                        <ConsentFormNew
                        isRadgiver={isRadgiver(bruker)}
                        formInfo={{ dataSharing }}
                    />
        
                    )}
                    </div>


        
                  
                </SideInnhold>
            )}
        </Side>
    );
}
