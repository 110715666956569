import { useEffect, useState } from "react";

import { ApplicationName, DomainName, ProcessName } from "common";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";

import {
    ChooseForsikring,
    ChooseInsured,
    FormState,
    MoreInfo,
    Receipt,
    StegProps
} from "@features/navneliste/components/Innmelding";
import {
    queryKey,
    useAnsattList,
    useAnsattOppdateringStatus,
    useOppdaterAnsatte
} from "@features/navneliste/queries/ansatt.queries";
import { formatInput } from "@fremtind/jkl-datepicker-react";
import { Loader } from "@fremtind/jkl-loader-react";
import { useQueryClient } from "@tanstack/react-query";

import { Side, SideHeader, SideInnhold } from "../components/Side";
import { FormTrackingMeta, SkjemaFlyt, SkjemaFlytProps, Steg } from "../components/SkjemaFlyt";
import "./AnsattInnmelding.scss";

const stegListe: Steg<StegProps>[] = [
    {
        stegnavn: "velg-ansatt",
        element: (props) => <ChooseInsured heading="Hvem skal meldes inn?" {...props} />
    },
    {
        stegnavn: "Velg forsikring",
        element: (props) =>
            props.availableAvtaler ? (
                <ChooseForsikring {...props} />
            ) : (
                <Loader
                    className="jkl-spacing-xl--top jkl-spacing-4xl--right"
                    textDescription={"Henter status på avtaler for ansatt"}
                />
            )
    },
    {
        stegnavn: "Siste detaljer",
        element: (props) => <MoreInfo {...props} />
    },
    {
        stegnavn: "Kvittering",
        element: (props) => <Receipt {...props} />
    }
];

function AnsattInnmeldingSkjemaFlyt(
    props: Omit<StegProps, "onFrem" | "onTilbake" | "onAvbryt" | "onFerdig" | "onRestart"> & SkjemaFlytProps<StegProps>
) {
    return <SkjemaFlyt {...props} />;
}

export function AnsattInnmelding() {
    const formMethods = useForm<FormState>({
        defaultValues: {
            innmeldtDato: formatInput(new Date()) as unknown as Date,
            selectedAvtaleIDs: []
        }
    });

    const [meta, setMeta] = useState<FormTrackingMeta>();

    const { indeks } = useParams<"indeks">();
    const insured = formMethods.watch("insured");

    const queryClient = useQueryClient();
    const mutation = useOppdaterAnsatte();
    const ansatteQuery = useAnsattList();
    const oppdateringStatusQuery = useAnsattOppdateringStatus(insured?.indeks);

    useEffect(() => {
        if (indeks && !formMethods.getValues().insured) {
            const insured = ansatteQuery.data?.entity.ansatte.find((ansatt) => ansatt.indeks === indeks);
            formMethods.setValue("insured", insured);
        }
    }, [ansatteQuery.data?.entity.ansatte, formMethods, indeks]);

    const trackingObject = {
        avtaler: formMethods.getValues("avtaler"),
        avtalegrupper: formMethods.getValues("avtaleGrupper"),
        imnmeldt: formMethods.getValues("innmeldtDato"),
        valgteAvtalerID: formMethods.getValues("selectedAvtaleIDs")
    };

    return (
        <Side className="ansatt-innmelding">
            <SideHeader title="Meld inn ansatt" />
            <SideInnhold
                footerProps={{ feedbackProps: { formFlowName: meta?.flowName, formStepName: meta?.stepName } }}
                loadingText="Henter list over ansatte"
                query={ansatteQuery}
            >
                <AnsattInnmeldingSkjemaFlyt
                    tracking={{
                        domain: DomainName.Insurance,
                        process: ProcessName.EditInsurance,
                        application: ApplicationName.AddEmployee,
                        applicationValues: trackingObject
                    }}
                    flytnavn="meld-inn-ansatt"
                    setFormTrackingMeta={setMeta}
                    formMethods={formMethods}
                    stegListe={stegListe.slice(indeks ? 1 : 0)}
                    insuredList={ansatteQuery.data?.entity.ansatte || []}
                    availableAvtaler={
                        oppdateringStatusQuery.isFetching ? undefined : oppdateringStatusQuery.data?.avtaler
                    }
                    mutation={mutation}
                    onMutationSuccess={() => {
                        queryClient.invalidateQueries({ queryKey: queryKey.list() });
                    }}
                    oversiktLink="/ansatte-navn"
                />
            </SideInnhold>
        </Side>
    );
}
