import React from 'react';
import { Toast } from '@components/Toast';
import { UseMutationResult } from '@tanstack/react-query';
import { IHttpError } from 'src/model/http.typer';
import { New_Datasharing } from '../consent.queries';


interface MutationToastProps {
    mutation: any;
    isRadgiver: boolean;

}

const DatasharingFormToast: React.FC<MutationToastProps> = ({ mutation, isRadgiver }) => {
    mutation = mutation as  UseMutationResult<unknown, IHttpError, New_Datasharing, unknown>;
    let severity: 'info' | 'success' | 'error' = 'info';
    let content = 'Oppdaterer samtykke...';
    let testautoid = 'samtykke-toast-info';
   

    if (mutation.isSuccess) {
        severity = 'success';
        content = 'Valget ditt for deling av data er oppdatert!';
        testautoid = 'samtykke-toast-success';
    } else if (mutation.isError) {
        if (isRadgiver && mutation.error?.message?.includes('Rådgiver')) {
            severity = 'error';
            content = mutation.error?.message;
            testautoid = 'samtykke-toast-error-radgiver';
        } else {
            severity = 'error';
            content = 'Kunne ikke oppdatere samtykke. Prøv en gang til eller kom tilbake senere.';
            testautoid = 'samtykke-toast-error';
        }
    }

    return (
        <Toast
            open={mutation.isPending || mutation.isSuccess || mutation.isError}
            onClose={mutation.reset}
            severity={severity}
            data-testautoid={testautoid}
        >
            {content}
        </Toast>
    );
};

export default DatasharingFormToast;