import React from "react";

import { FormProvider, useForm } from "react-hook-form";
import { Trackingkey, track } from "src/tracking";

import { New_Datasharing, UpdateReservationQuery, useUpdateConsent_NEW } from "../consent.queries";
import { ConsentDataSharing } from "./ConsentDataSharing";
import DatasharingFormToast from "./DatasharingFormToast";
import "./ConsentForm.scss";

export type ReservationFormData = {
    dataSharing: boolean;
};

const toSubmitData = (formData: UpdateReservationQuery): New_Datasharing => ({
    consent: formData.dataSharing || false
});

interface Props {
    isRadgiver: boolean;
    formInfo: ReservationFormData;
}

export const ConsentFormNew: React.FC<Props> = ({ isRadgiver, formInfo }: Props) => {
    
    const form = useForm<ReservationFormData>({
        defaultValues: {
            dataSharing: formInfo.dataSharing
        },
        shouldFocusError: false
    });

    const { handleSubmit, watch } = form;

    const dataSharing = watch("dataSharing");

    
    const mutation = useUpdateConsent_NEW();

    const onSubmit = (formData: UpdateReservationQuery) => {
        mutation.reset();

        mutation.mutate(toSubmitData(formData), {
            onSuccess: () => {
                track({
                    hendelse: Trackingkey.Samtykke,
                    delingAvData: dataSharing ? "Ja" : "Nei"
                });
            }
        });
    };

    return (
        <FormProvider {...form}>
            <form className="consent" onSubmit={handleSubmit(onSubmit)}>
                <ConsentDataSharing  />
                 <DatasharingFormToast mutation={mutation} isRadgiver={isRadgiver} />
            </form>
        </FormProvider>
    );
};

