import { brukerHarTilgang } from "src/common/rolle";
import { BrukerRolle } from "src/model/bruker.typer";
import { IHttpError } from "src/model/http.typer";
import { Flag, useFeatureflag } from "src/common/featureflag";
import { http } from "@features/core";
import { FbmResponseEntityVoid, SamtykkeOgKontaktResponse } from "@model/gen";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import { useBruker } from "../bruker/queries";


export type UpdateReservationQuery = {
    reservation?: boolean;
    dataSharing?: boolean;
    email?: string;
};

export type NEW_UpdateDigitalCommunication = {
    reservation: boolean;
    email: string,
};

export type New_Datasharing = { 
    consent: boolean;
};

type New_DatasharingResponse = {
    consent: boolean,
    consentDate: Date
};

const reservationAndSamtykkeQueryKey = ["reservation"];
const consentQueryKey_NEW = ["consent_NEW"];



// FETCH RESERVATION OLD (CURRENT) //
const fetchReservation = async () => {
    const samtykke = await http.getEntity<SamtykkeOgKontaktResponse>("samtykke");
    return {
        consent: {
            email: samtykke.entity.kontaktInfo.epost.epost,
            consent: samtykke.entity.samtykke.ekunde?.kundeSamtykkeGitt
        },
        dataSharing: samtykke.entity.samtykke.delingAvData?.kundeSamtykkeGitt,
        feilmelding: samtykke.feilmelding
    };
};

// USE FETCH RESERVATION OLD (CURRENT) //
export const useReservation = () => {
    const { bruker } = useBruker();
    return useQuery({
        queryKey: reservationAndSamtykkeQueryKey,
        queryFn: fetchReservation,
        enabled:
            !!bruker?.gjeldendeOrganisasjonsnummer &&
            brukerHarTilgang(bruker, [BrukerRolle.ADMINISTRATOR, BrukerRolle.RADGIVER, BrukerRolle.RADGIVER_DNB]),
        refetchOnWindowFocus: "always",
        staleTime: Infinity
    });
};

// FETCH DATASHARING NEW //
const fetchDatasharing__NEW = async () => {
    const { consent } = await http.get<New_DatasharingResponse>("consent/crossdomain");
    return {
        dataSharing: consent
    };
};

// USE FETCH DATASHARING NEW //
export const useDatasharing_NEW = () => {
    const shouldUseNewService = useFeatureflag(Flag.NEW_CONSENT);
    const { bruker } = useBruker();
    const consent = useQuery({
        queryKey: consentQueryKey_NEW,
        queryFn: fetchDatasharing__NEW,
        enabled:
        shouldUseNewService &&
            !!bruker?.gjeldendeOrganisasjonsnummer &&
            brukerHarTilgang(bruker, [
                BrukerRolle.ADMINISTRATOR,
                BrukerRolle.ADMINISTRATOR_DNB,
                BrukerRolle.RADGIVER,
                BrukerRolle.RADGIVER_DNB
            ]),
         refetchOnWindowFocus: "always"
    });

    return { consent };
};


// UPDATE DIGITALCOMMUNICATION AND DATASHARING OLD (CURRENT) //
const updateReservation = async ({ reservation, dataSharing, email }: UpdateReservationQuery) => {
    return http.post<FbmResponseEntityVoid>("samtykke", {
        kontaktInfo: reservation ? undefined : { epost: email },
        samtykke: {
            ekunde: {
                kundeSamtykkeGitt: !reservation
            },
            delingAvData: {
                kundeSamtykkeGitt: dataSharing
            }
        }
    });
};

export const useUpdateReservation = () => {
    const queryClient = useQueryClient();
    return useMutation<unknown, IHttpError, UpdateReservationQuery>({
        mutationFn: updateReservation,
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: reservationAndSamtykkeQueryKey  });
        }
    });
};


// UPDATE DATASHARING NEW //
const updateConsent_NEW = async ({ consent }: New_Datasharing) => {
    return http.put<New_Datasharing>("consent/crossdomain", {
        consent: consent,
        consentDate: new Date()
    });
};

export const useUpdateConsent_NEW = () => {
    const { bruker } = useBruker();
    const queryClient = useQueryClient();
    return useMutation<unknown, IHttpError, New_Datasharing>({
        mutationFn: async (data) => {
            if ((bruker?.roller.includes(BrukerRolle.RADGIVER) || bruker?.roller.includes(BrukerRolle.RADGIVER_DNB))) {
                throw new Error('Rådgiver kan ikke gjøre endringer på samtykke til deling av data. Kunden må endre dette selv.');
            }
            return updateConsent_NEW(data);
        },
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: consentQueryKey_NEW });
        }
    });
};

