import React from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useUpdateReservationAndConsent,  UpdateReservationQuery } from "../DocumentsSection/GrunndataDocumentsSection.queries";
import { ReservationDigitalCommunication } from "./ReservationDigitalCommunication";
import DigitalCommunicationToast from "./DigitalCommunicationToast";



export type ReservationFormData = {
    reservation: boolean;
    email: string;
};

const toSubmitData = (formData: UpdateReservationQuery) => ({
    reservation: formData.reservation || false,
    email: formData.email || ""
});

interface Props {
    isRadgiver: boolean;
    isDNB: boolean;
    formInfo: ReservationFormData;
}

export const ReservationFormDigitalCommunication: React.FC<Props> = ({ formInfo, isRadgiver }: Props) => {
    const form = useForm<ReservationFormData>({
        defaultValues: {
            reservation: formInfo.reservation,
            email: formInfo.email
        },
        shouldFocusError: false
    });

    const { handleSubmit } = form;
    
    const mutation = useUpdateReservationAndConsent();

    const initialEmail = formInfo.email;
    
    const onSubmit = (formData: UpdateReservationQuery) => {
        mutation.reset();
        mutation.mutate(toSubmitData(formData), {
        });
    };

    return (
        <FormProvider {...form}>
            <form className={"reservation"} onSubmit={handleSubmit(onSubmit)}>
                <ReservationDigitalCommunication initialEmail={initialEmail}  />
                <DigitalCommunicationToast mutation={mutation} isRadgiver={isRadgiver} />
            </form>
        </FormProvider>
    );
};

