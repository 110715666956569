import React from 'react';
import { IHttpError } from 'src/model/http.typer';
import { Toast } from '@components/Toast';
import { UseMutationResult } from '@tanstack/react-query';
import {  UpdateReservationQuery } from '../../../consent/consent.queries';

interface MutationData {
    feilkoder: string[];
}

interface DigitalCommunicationToastProps {
    mutation: UseMutationResult<unknown, IHttpError, UpdateReservationQuery, unknown>;
    isRadgiver?: boolean;
}

const DigitalCommunicationToast: React.FC<DigitalCommunicationToastProps> = ({ mutation, isRadgiver }) => {
    const mutationData = mutation as unknown as UseMutationResult<MutationData>;
    let severity: 'info' | 'success' | 'error' = 'info';
    let content = 'Oppdaterer valg...';
    let testautoid = 'datasharing-toast-info';

    let errorTitle = "Oppdatering feilet";

    const defaultErrorMsg = "Prøv en gang til eller kom tilbake senere.";

    const  getErrorMessage = (msgString: string) => {
        if (msgString.toLowerCase().includes('epost.ugyldig')) {
            errorTitle = "Ugyldig e-postadresse";
            return 'Prøv på nytt med en annen e-postadresse.';
        } else if ( msgString.toLowerCase().includes('epost.mangler')) {
            return "E-postadresse mangler. Vennligst oppgi e-postadresse.";
        } else {
        return defaultErrorMsg;
    }
    };

    if (mutationData.data?.feilkoder && mutationData.data?.feilkoder?.length > 0) {
        severity = 'error';
        content =  getErrorMessage(mutationData.data?.feilkoder?.[0]?.toString() ?? '');
        testautoid = 'datasharing-toast-error';
    } else if (mutationData.isSuccess) {
        severity = 'success';
        content = 'Valget ditt er oppdatert!';
        testautoid = 'samtykke-toast-success';
    } else if (mutationData.isError) {
            severity = 'error';
            content = isRadgiver ? "Rådgiver kan ikke gjøre endringer på samtykke. Kunden må endre dette selv." : defaultErrorMsg;
            testautoid = 'samtykke-toast-error';
        }

    return (
        <Toast
            title={!mutation.isPending && severity === "error" ? errorTitle : ""}
            open={mutationData.isPending || mutationData.isSuccess || mutationData.isError}
            onClose={mutationData.reset}
            severity={severity}
            data-testautoid={testautoid}
        >
            {content}
        </Toast>
    );
};

export default DigitalCommunicationToast;