import n from "mixpanel-browser";
const a = {
  medium: 680,
  large: 1200,
  xl: 1600
};
function m(e) {
  return e && e.__esModule && Object.prototype.hasOwnProperty.call(e, "default") ? e.default : e;
}
var r = {
  color: {
    brand: {
      snohvit: "#F9F9F9",
      varde: "#E0DBD4",
      granitt: "#1B1917",
      hvit: "#FFFFFF",
      svart: "#000000",
      skifer: "#313030",
      fjellgra: "#444141",
      stein: "#636060",
      svaberg: "#C8C5C3",
      dis: "#ECE9E5",
      sand: "#F4F2EF"
    },
    functional: {
      info: "#D3D3F6",
      infoDark: "#A9A9CA",
      success: "#ACD3B5",
      successDark: "#94B79B",
      warning: "#EFDD9E",
      warningDark: "#DECC8D",
      error: "#F6B3B3",
      errorDark: "#DE9E9E"
    },
    background: {
      page: {
        light: "#F4F2EF",
        dark: "#1B1917"
      },
      pageVariant: {
        light: "#F9F9F9",
        dark: "#1B1917"
      },
      container: {
        light: "#F9F9F9",
        dark: "#313030"
      },
      containerLow: {
        light: "#ECE9E5",
        dark: "#000000"
      },
      containerHigh: {
        light: "#FFFFFF",
        dark: "#313030"
      },
      containerInverted: {
        light: "#1B1917",
        dark: "#F9F9F9"
      },
      containerSubdued: {
        light: "#C8C5C3",
        dark: "#636060"
      },
      input: {
        base: {
          light: "transparent",
          dark: "transparent"
        },
        focus: {
          light: "#FFFFFF",
          dark: "#313030"
        }
      },
      action: {
        light: "#1B1917",
        dark: "#F9F9F9"
      },
      interactive: {
        light: "transparent",
        dark: "transparent"
      },
      interactiveHover: {
        light: "#ECE9E5",
        dark: "#444141"
      },
      interactiveSelected: {
        light: "#E0DBD4",
        dark: "#636060"
      },
      alert: {
        neutral: {
          light: "#E0DBD4",
          dark: "#E0DBD4"
        },
        info: {
          light: "#D3D3F6",
          dark: "#A9A9CA"
        },
        success: {
          light: "#ACD3B5",
          dark: "#94B79B"
        },
        warning: {
          light: "#EFDD9E",
          dark: "#DECC8D"
        },
        error: {
          light: "#F6B3B3",
          dark: "#DE9E9E"
        }
      }
    },
    text: {
      default: {
        light: "#1B1917",
        dark: "#F9F9F9"
      },
      subdued: {
        light: "#636060",
        dark: "#C8C5C3"
      },
      inverted: {
        light: "#F9F9F9",
        dark: "#1B1917"
      },
      onAction: {
        light: "#F9F9F9",
        dark: "#1B1917"
      },
      interactive: {
        light: "#1B1917",
        dark: "#F9F9F9"
      },
      interactiveHover: {
        light: "#636060",
        dark: "#C8C5C3"
      },
      onAlert: {
        light: "#1B1917",
        dark: "#1B1917"
      },
      onAlertSubdued: {
        light: "#444141",
        dark: "#444141"
      }
    },
    border: {
      action: {
        light: "#1B1917",
        dark: "#F9F9F9"
      },
      input: {
        light: "#636060",
        dark: "#C8C5C3"
      },
      inputFocus: {
        light: "#1B1917",
        dark: "#F9F9F9"
      },
      separator: {
        light: "#C8C5C3",
        dark: "#636060"
      },
      separatorStrong: {
        light: "#636060",
        dark: "#C8C5C3"
      },
      separatorHover: {
        light: "#1B1917",
        dark: "#F9F9F9"
      },
      subdued: {
        light: "#C8C5C3",
        dark: "#636060"
      }
    },
    svart: "#000",
    granitt: "#1b1917",
    skifer: "#313030",
    fjellgra: "#444141",
    stein: "#636060",
    svaberg: "#c8c5c3",
    varde: "#e0dbd4",
    dis: "#ece9e5",
    sand: "#f4f2ef",
    snohvit: "#f9f9f9",
    hvit: "#fff",
    suksess: "#acd3b5",
    suksessDark: "#94b79b",
    feil: "#f6b3b3",
    feilDark: "#de9e9e",
    info: "#d3d3f6",
    infoDark: "#a9a9ca",
    advarsel: "#efdd9e",
    advarselDark: "#decc8d"
  },
  spacing: {
    0: "0",
    2: "0.125rem",
    4: "0.25rem",
    8: "0.5rem",
    12: "0.75rem",
    16: "1rem",
    24: "1.5rem",
    32: "2rem",
    40: "2.5rem",
    64: "4rem",
    104: "6.5rem",
    168: "10.5rem"
  },
  icon: {
    weight: {
      normal: "300",
      bold: "500"
    }
  },
  typography: {
    weight: {
      normal: "400",
      bold: "700"
    },
    font: {
      size: {
        16: "1rem",
        18: "1.125rem",
        20: "1.25rem",
        21: "1.3125rem",
        23: "1.4375rem",
        25: "1.5625rem",
        26: "1.625rem",
        28: "1.75rem",
        30: "1.875rem",
        36: "2.25rem",
        44: "2.75rem",
        56: "3.5rem"
      }
    },
    line: {
      height: {
        24: "1.5rem",
        28: "1.75rem",
        32: "2rem",
        36: "2.25rem",
        40: "2.5rem",
        44: "2.75rem",
        52: "3.25rem",
        64: "4rem"
      }
    },
    title: {
      small: {
        fontSize: "2.25rem",
        lineHeight: "2.75rem",
        fontWeight: "400"
      },
      base: {
        fontSize: "3.5rem",
        lineHeight: "4rem",
        fontWeight: "400"
      }
    },
    titleSmall: {
      small: {
        fontSize: "1.875rem",
        lineHeight: "2.25rem",
        fontWeight: "400"
      },
      base: {
        fontSize: "2.75rem",
        lineHeight: "3.25rem",
        fontWeight: "400"
      }
    },
    heading_1: {
      small: {
        fontSize: "1.625rem",
        lineHeight: "2rem",
        fontWeight: "400"
      },
      base: {
        fontSize: "2.25rem",
        lineHeight: "2.75rem",
        fontWeight: "400"
      }
    },
    heading_2: {
      small: {
        fontSize: "1.4375rem",
        lineHeight: "2rem",
        fontWeight: "400"
      },
      base: {
        fontSize: "1.75rem",
        lineHeight: "2.5rem",
        fontWeight: "400"
      }
    },
    heading_3: {
      small: {
        fontSize: "1.3125rem",
        lineHeight: "1.75rem",
        fontWeight: "700"
      },
      base: {
        fontSize: "1.5625rem",
        lineHeight: "2rem",
        fontWeight: "700"
      }
    },
    heading_4: {
      small: {
        fontSize: "1.125rem",
        lineHeight: "1.5rem",
        fontWeight: "700"
      },
      base: {
        fontSize: "1.3125rem",
        lineHeight: "1.75rem",
        fontWeight: "700"
      }
    },
    heading_5: {
      small: {
        fontSize: "1rem",
        lineHeight: "1.5rem",
        fontWeight: "700"
      },
      base: {
        fontSize: "1rem",
        lineHeight: "1.5rem",
        fontWeight: "700"
      }
    },
    body: {
      small: {
        fontSize: "1.125rem",
        lineHeight: "1.75rem",
        fontWeight: "400"
      },
      base: {
        fontSize: "1.25rem",
        lineHeight: "2rem",
        fontWeight: "400"
      }
    },
    small: {
      small: {
        fontSize: "1rem",
        lineHeight: "1.5rem",
        fontWeight: "400"
      },
      base: {
        fontSize: "1rem",
        lineHeight: "1.5rem",
        fontWeight: "400"
      }
    }
  }
}, f = {}, k = {};
(function(e) {
  Object.defineProperty(e, "__esModule", {
    value: !0
  }), Object.defineProperty(e, "default", {
    enumerable: !0,
    get: function() {
      return i;
    }
  });
  function t(l, o) {
    return {
      handler: l,
      config: o
    };
  }
  t.withOptions = function(l, o = () => ({})) {
    const d = function(g) {
      return {
        __options: g,
        handler: l(g),
        config: o(g)
      };
    };
    return d.__isOptionsFunction = !0, d.__pluginFunction = l, d.__configFunction = o, d;
  };
  const i = t;
})(k);
(function(e) {
  Object.defineProperty(e, "__esModule", {
    value: !0
  }), Object.defineProperty(e, "default", {
    enumerable: !0,
    get: function() {
      return l;
    }
  });
  const t = /* @__PURE__ */ i(k);
  function i(o) {
    return o && o.__esModule ? o : {
      default: o
    };
  }
  const l = t.default;
})(f);
let c = f;
var b = (c.__esModule ? c : { default: c }).default;
const v = /* @__PURE__ */ m(b), w = {
  content: {
    relative: !0,
    files: ["./src/**/*.{js,ts,jsx,tsx,mdx}", "./lib/**/*.{js,ts,jsx,tsx,mdx}"]
  },
  theme: {
    extend: {
      spacing: r.spacing,
      boxShadow: {
        default: "0px 4px 20px 0px #31303033"
      }
    },
    colors: {
      ...r.color.brand,
      ...r.color.functional,
      "background-page": "var(--jkl-color-background-page)",
      "page-variant": "var(--jkl-color-background-page-variant)",
      "background-container": "var(--jkl-color-background-container)",
      "background-container-low": "var(--jkl-color-background-container-low)",
      "background-container-high": "var(--jkl-color-background-container-high)",
      "background-input-base": "var(--jkl-color-background-input-base)",
      "background-input-focus": "var(--jkl-color-background-input-focus)",
      "background-action": "var(--jkl-color-background-action)",
      "background-interactive": "var(--jkl-color-background-interactive)",
      "background-interactive-hover": "var(--jkl-color-background-interactive-hover)",
      "background-interactive-selected": "var(--jkl-color-background-interactive-selected)",
      "background-alert-neutral": "var(--jkl-color-background-alert-neutral)",
      "background-alert-info": "var(--jkl-color-background-alert-info)",
      "background-alert-success": "var(--jkl-color-background-alert-success)",
      "background-alert-warning": "var(--jkl-color-background-alert-warning)",
      "background-alert-error": "var(--jkl-color-background-alert-error)",
      "text-default": "var(--jkl-color-text-default)",
      "text-subdued": "var(--jkl-color-text-subdued)",
      "text-inverted": "var(--jkl-color-text-inverted)",
      "text-on-action": "var(--jkl-color-text-on-action)",
      "text-interactive": "var(--jkl-color-text-interactive)",
      "text-interactive-hover": "var(--jkl-color-text-interactive-hover)",
      "text-on-alert": "var(--jkl-color-text-on-alert)",
      "border-action": "var(--jkl-color-border-action)",
      "border-input": "var(--jkl-color-border-input)",
      "border-input-focus": "var(--jkl-color-border-input-focus)",
      "border-separator": "var(--jkl-color-border-separator)",
      "border-separator-strong": "var(--jkl-color-border-separator-strong)",
      "border-separator-hover": "var(--jkl-color-border-separator-hover)"
    },
    fontWeight: r.typography.weight,
    fontSize: r.typography.font.size,
    lineHeight: r.typography.line.height,
    fontFamily: {},
    screens: {
      sm: `${a.medium}px`,
      md: `${a.large}px`,
      "coverage-table": "770px"
    }
  },
  plugins: [
    v(function({ addComponents: e }) {
      e({
        ".title": {
          ...r.typography.title.small,
          [`@media (min-width: ${a.medium}px)`]: {
            ...r.typography.title.base
          }
        },
        ".title-small": {
          ...r.typography.titleSmall.small,
          [`@media (min-width: ${a.medium}px)`]: {
            ...r.typography.titleSmall.base
          }
        },
        ".heading-1": {
          ...r.typography.heading_1.small,
          [`@media (min-width: ${a.medium}px)`]: {
            ...r.typography.heading_1.base
          }
        },
        ".heading-2": {
          ...r.typography.heading_2.small,
          [`@media (min-width: ${a.medium}px)`]: {
            ...r.typography.heading_2.base
          }
        },
        ".heading-3": {
          ...r.typography.heading_3.small,
          [`@media (min-width: ${a.medium}px)`]: {
            ...r.typography.heading_3.base
          }
        },
        ".heading-4": {
          ...r.typography.heading_4.small,
          [`@media (min-width: ${a.medium}px)`]: {
            ...r.typography.heading_4.base
          }
        },
        ".heading-5": {
          ...r.typography.heading_5.small,
          [`@media (min-width: ${a.medium}px)`]: {
            ...r.typography.heading_5.base
          }
        },
        ".body": {
          ...r.typography.body.small,
          [`@media (min-width: ${a.medium}px)`]: {
            ...r.typography.body.base
          }
        },
        ".body-size": {
          fontSize: r.typography.body.small.fontSize,
          lineHeight: r.typography.body.small.lineHeight,
          [`@media (min-width: ${a.medium}px)`]: {
            fontSize: r.typography.body.base.fontSize,
            lineHeight: r.typography.body.base.lineHeight
          }
        },
        ".small": {
          ...r.typography.small.small,
          [`@media (min-width: ${a.medium}px)`]: {
            ...r.typography.small.base
          }
        },
        ".max-width-content": {
          maxWidth: "min(1400px, calc(100vw - 2rem))",
          [`@media (min-width: ${a.xl}px)`]: {
            padding: "0"
          }
        }
      });
    })
  ]
};
function y(e) {
  const t = {};
  for (const i in e)
    Object.prototype.hasOwnProperty.call(e, i) && (typeof e[i] == "object" && e[i] !== null ? t[i] = y(e[i]) : t[i] = "*****");
  return t;
}
var F = /* @__PURE__ */ ((e) => (e.Login = "Login", e.Button = "Knappeklikk", e.Link = "Lenkeklikk", e.Input = "Input", e.Notification = "Varsel", e.ViewPage = "Sidevisning", e.FillApplication = "Skjemautfylling", e))(F || {}), j = /* @__PURE__ */ ((e) => (e.Frontpage = "", e.Login = "Bedriftsvelger", e.Insurance = "Forsikring", e.Claims = "Skadesaker", e.Documents = "Dokumenter", e.Access = "Tilganger", e.Beneficiaries = "Reelle rettighetshavere", e.Preferences = "Preferanser", e.Support = "Hjelp", e))(j || {}), _ = /* @__PURE__ */ ((e) => (e.None = "", e.CompanyChoice = "Velg bedrift", e.EditInsurance = "Endre forsikring", e.PurchaseInsurance = "Kjøp forsikring", e.CancelInsurance = "Avslutt forsikring", e.CreateClaim = "Meld skade", e.DownloadDocument = "Last ned dokument", e.EditAccess = "Endre tilganger", e.EditBeneficiaries = "Endre reelle rettighetshaver", e.ContactInsuranceAdvisor = "Kontakt forsikringsrådgiver", e))(_ || {}), x = /* @__PURE__ */ ((e) => (e.PurchaseAny = "Kjøp (generell)", e.PurchaseKjoretoy = "Kjøp kjøretøy", e.PurchaseBil = "Kjøp bilforsikring", e.PurchaseReise = "Kjøp reiseforsikring", e.PurchaseYrkesskade = "Kjøp yrkesskadeforsikring", e.EditCarMilage = "Endre kilometerstand", e.AddEmployee = "Meld inn ansatt", e.EditEmployees = "Endre antall ansatte", e.RemoveEmployee = "Meld ut ansatt", e.AddAccess = "Legg til tilgang", e.EditAccess = "Endre tilgang", e.RemoveAccess = "Fjern tilgang", e.AddBeneficiary = "Legg til reell rettighetshaver", e.RemoveBeneficiary = "Fjern reell rettighetshaver", e))(x || {}), C = /* @__PURE__ */ ((e) => (e.DNB = "dnb", e.SB1 = "sb1", e.MEG = "meg", e))(C || {});
const E = !1, B = {
  init(e, t = !1) {
    n.init(e.trackingDetails.mixpanelProjectKey, {
      debug: t,
      disable_persistence: E,
      opt_out_tracking_by_default: !0,
      ip: !1,
      api_host: "https://api-eu.mixpanel.com"
    }), n.register({ source: "fbm" });
  },
  track(e) {
    const t = (i) => {
      i && n.track(i.event, {
        ...i
      });
    };
    Array.isArray(e) ? e.forEach((i) => t(i)) : t(e);
  },
  register(e) {
    n.register(e);
  },
  optIn(e, t, i) {
    var d, g;
    n.opt_in_tracking();
    const l = [
      "opprettet",
      "loggetInn",
      "bankIdSertifisert"
    ], o = Object.entries(e).reduce(
      (h, [u, p]) => (l.includes(u) && (h[u] = p), h),
      {}
    );
    n.register({
      organisasjon: e.gjeldendeOrganisasjonsnummer,
      distributor: t,
      isRadgiver: i,
      roller: e.roller,
      bank: (d = e.radgiverKontaktInfo) == null ? void 0 : d.arbeidssted
    }), n.identify(e.trackingDetails.trackingIdForFodselsnummer), n.people.set_once(o), n.people.set("vindubredde", window.innerWidth), n.people.set("vinduhøyde", window.innerHeight), n.people.set("organisasjoner", e.organizations), n.people.set("bank", (g = e.radgiverKontaktInfo) == null ? void 0 : g.arbeidssted);
  },
  optOut() {
    n.opt_out_tracking();
  }
}, s = [B];
function D(e) {
  s.forEach((t) => t.init(e));
}
function H(e) {
  s.forEach((t) => t.track(e));
}
function z(e) {
  s.forEach((t) => t.register(e));
}
function W(e, t, i) {
  s.forEach((l) => l.optIn(e, t, i));
}
function I() {
  s.forEach((e) => e.optOut());
}
export {
  x as ApplicationName,
  C as Distributor,
  j as DomainName,
  F as EventName,
  _ as ProcessName,
  B as TrackerMixpanel,
  y as censorObject,
  D as initTracking,
  W as optInTracking,
  I as optOutTracking,
  z as registerTrackingProps,
  w as tailwindConfig,
  H as trackEvent
};
