import { Bruker } from "src/model/bruker.typer";

import { WarningMessageBox } from "@fremtind/jkl-message-box-react";

interface AlertSectionProps {
    bruker: Bruker;
    children?: React.ReactNode;
}

export function AlertSection({ bruker, children }: AlertSectionProps) {
    let showDynamicMenuError = false;
    if (bruker?.dynamicMenu?.rrh.error === true) {
        showDynamicMenuError = true;
    }
    if (showDynamicMenuError) {
        return (
            <section>
                {showDynamicMenuError && (
                    <WarningMessageBox className="bm-global-alert-message" fullWidth>
                        Behandling av reelle rettighetshavere er for tiden utilgjengelig på grunn av problemer hos
                        underleverandør. Dette gjør også at signering av tilbud er utilgjengelig.
                    </WarningMessageBox>
                )}
                {children}
            </section>
        );
    }
    return null;
}
