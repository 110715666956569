import { useEffect } from "react";

import { Link } from "react-router-dom";
import { Trackingkey, track } from "src/tracking";

import { NavCard } from "@fremtind/jkl-card-react";
import { TagType } from "@fremtind/jkl-card-react/build/NavCard";
import { TaskResponseDto } from "@model/gen/models/TaskResponseDto";

import "./TaskCard.scss";

interface TaskCardProps {
    task: TaskResponseDto;
    showImage?: boolean;
}

export const TaskCard = (props: TaskCardProps) => {
    const { title, description, url, image, category, subjects } = props.task;

    const taskTitle: string = title || "";
    const showImage = props.showImage || false;

    useEffect(() => {
        track({
            hendelse: Trackingkey.Varsel,
            type: props.task.category,
            variant: "neutral",
            tittel: props.task.title,
            description: props.task.description,
            url: props.task.url,
            status: props.task.status,
            createdDate: props.task.createdDate,
            subjects: props.task.subjects,
            products: props.task.products,
            category: props.task.category,
            priority: props.task.priority,
            productCode: props.task.productCode,
            agreementNumber: props.task.agreementNumber,
            role: props.task.role
        });
    }, [props.task]);

    const getImage = () => {
        if (showImage && image) {
            return {
                src: `/bedrift/static/images/${image}`,
                alt: ""
            };
        } else {
            return undefined;
        }
    };

    const getTag = () => {
        switch (category) {
            case "Aktuelt":
                return {
                    text: subjects ? subjects[0] : "Aktuelt",
                    type: "info" as TagType
                };

            case "Oppgave":
                if (props?.task?.priority === "VERY_IMPORTANT") {
                    return {
                        text: "Viktig",
                        type: "error" as TagType
                    };
                } else if (props?.task?.priority === "INFO") {
                    return {
                        text: "Info",
                        type: "info" as TagType
                    };
                } else {
                    return {
                        text: "Viktig",
                        type: "warning" as TagType
                    };
                }
            default:
                return undefined;
        }
    };

    if (url?.includes("http")) {
        return (
            <NavCard
                title={taskTitle}
                description={description}
                href={url}
                target="_blank"
                tag={getTag()}
                image={getImage()}
                className="bm-task-card"
                onClick={() => {
                    track({ hendelse: Trackingkey.LenkeTrykk, lenkeId: taskTitle, ekstern: true, type: "varsel" });
                }}
            />
        );
    }

    return (
        <NavCard
            title={taskTitle}
            description={description}
            to={`/${url}`}
            tag={getTag()}
            image={getImage()}
            component={Link}
            className="bm-task-card"
            onClick={() => {
                track({ hendelse: Trackingkey.LenkeTrykk, lenkeId: taskTitle, ekstern: false, type: "varsel" });
            }}
        />
    );
};
