import { http } from "@features/core";
import { IHttpError } from "src/model/http.typer";
import { KundeFeilmelding } from "src/model/feilkoder.object";
import { Flag, useFeatureflag } from "src/common/featureflag";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { brukerHarTilgang } from "src/common/rolle";
import { BrukerRolle } from "src/model/bruker.typer";
import { FbmResponseEntityVoid, SamtykkeOgKontaktResponse } from "@model/gen";

import { useBruker } from "@features/bruker/queries";


export type FetchReservationAndConsent = {
consent: {
    email: string;
    reservation: boolean;
};
dataSharing: boolean | undefined;
feilmelding: KundeFeilmelding | undefined;
};

export type ConsentResponse = {
    reservation: boolean;
    email: string | undefined;
}

export type NEW_DigitalCommunicationResponse = {
    reservation: boolean;
    changedDate: Date;
    email?: string,
};

export type NEW_UpdateDigitalCommunication = {
    reservation: boolean;
    email: string,
};

// FETCH DIGITALCOMMUNICATION OLD //
export type UpdateReservationQuery = {
    reservation?: boolean;
    dataSharing?: boolean;
    email?: string;
};

const reservationQueryKey_OLD = ["reservation_OLD"];
const reservationQueryKey_NEW = ["reservation_NEW"];

export const grunndataContactInfoQueryKeys = {
    all: ["Kontaktinfo organisasjon"]
};


// FETCH RESERVATION OLD (CURRENT) //
const fetchReservationAndConsent = async () => {
    const samtykke = await http.getEntity<SamtykkeOgKontaktResponse>("samtykke");
   return {
        consent: {
            email: samtykke.entity.kontaktInfo.epost.epost,
            reservation: !samtykke.entity.samtykke.ekunde?.kundeSamtykkeGitt
        },
        dataSharing: samtykke.entity.samtykke.delingAvData?.kundeSamtykkeGitt,
        feilmelding: samtykke.feilmelding
    };
};


// USE FETCH RESERVATION OLD (CURRENT) //
export const useReservationAndConsent = () => {
    const { bruker } = useBruker();
    return useQuery({
        queryKey: reservationQueryKey_OLD,
        queryFn: fetchReservationAndConsent,
        enabled:
            !!bruker?.gjeldendeOrganisasjonsnummer &&
            brukerHarTilgang(bruker, [BrukerRolle.ADMINISTRATOR, BrukerRolle.RADGIVER, BrukerRolle.RADGIVER_DNB]),
       refetchOnWindowFocus: "always",
        staleTime: Infinity
    });
};


// UPDATE DIGITALCOMMUNICATION AND DATASHARING OLD (CURRENT) //
const updateReservationAndConsent = async ({ reservation, dataSharing, email }: UpdateReservationQuery) => {
    return http.post<FbmResponseEntityVoid>("samtykke", {
        kontaktInfo: reservation ? undefined : { epost: email },
        samtykke: {
            ekunde: {
                kundeSamtykkeGitt: !reservation
            },
            delingAvData: {
                kundeSamtykkeGitt: dataSharing
            }
        }
    });
};

// USE UPDATE DIGITALCOMMUNICATION AND DATASHARING OLD (CURRENT) //
export const useUpdateReservationAndConsent = () => {
    const queryClient = useQueryClient();
    return useMutation<unknown, IHttpError, UpdateReservationQuery>({
        mutationFn: updateReservationAndConsent,
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: reservationQueryKey_OLD  });
        }
    });
};


// FETCH DIGITALCOMMUNICATION NEW //
const fetchDigitalCommunication_NEW = async () => {
        const digitalCommunication = await http.get<NEW_DigitalCommunicationResponse>("consent/digitalcommunication");
    return {
        reservation: digitalCommunication.reservation,
        changedDate: digitalCommunication.changedDate,
        email: digitalCommunication.email
    };
};

// USE FETCH DIGITALCOMMUNICATION NEW //
export const useDigitalCommunication = () => {
    const { bruker } = useBruker();
    const shouldUseNewService = useFeatureflag(Flag.NEW_CONSENT);
    const reservation = useQuery({
        queryKey: reservationQueryKey_NEW,
        queryFn: fetchDigitalCommunication_NEW,
        enabled:
        shouldUseNewService &&
            !!bruker?.gjeldendeOrganisasjonsnummer &&
            brukerHarTilgang(bruker, [
                BrukerRolle.ADMINISTRATOR,
                BrukerRolle.ADMINISTRATOR_DNB,
                BrukerRolle.RADGIVER,
                BrukerRolle.RADGIVER_DNB
            ]),
        refetchOnWindowFocus: "always",
        refetchOnMount: "always"
    });


    return { reservation };
};

// UPDATE DIGITALCOMMUNICATION NEW //
const updateDigitalCommunication = async ({ reservation, email }: NEW_UpdateDigitalCommunication) => {
    return http.put<NEW_UpdateDigitalCommunication>("consent/digitalcommunication", {
        reservation: reservation,
        changedDate: new Date(),
        email: email
    });
};

export const useUpdateDigitalCommunication = () => {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: updateDigitalCommunication,
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: reservationQueryKey_NEW });
        },
        onError: (error) => {
            return error;
        }
    });
};