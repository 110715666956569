import { Link } from "@fremtind/jkl-core";
import { InfoMessageBox } from "@fremtind/jkl-message-box-react";
import type { AgreementDetails } from "@model/gen";

import styles from "./SmartDelayMessage.module.scss";

interface Props {
    agreementDetails: AgreementDetails;
}

export const SmartDelayMessage = ({ agreementDetails }: Props) => {
    const isNumberBased = agreementDetails.groupInsuranceType === "NUMBER_BASED";
    const isTravelInsurance = agreementDetails.productCode === "Y44";

    if (!isTravelInsurance || !isNumberBased) {
        return null;
    }

    return (
        <InfoMessageBox
            fullWidth={true}
            title="SmartDelay+"
            className={styles.smartDelay}
            data-testid="smart-delay-message"
        >
            Ved flyforsinkelse får dine ansatte gratis tilgang til utvalgte flyplasslounger verden over. Flyvningen må
            registreres senest to timer før planlagt reise hos{" "}
            <Link target="_blank" href="https://norsk.smartdelay.com/fremtind/validation/validate-eligibility">
                SmartDelay+
            </Link>
            . Bruk kodeord FremtindSD.
        </InfoMessageBox>
    );
};
