import React from "react";

import { useFormContext } from "react-hook-form";
import { Typography } from "@components/Typography";
import { Accordion, AccordionItem } from "@fremtind/jkl-accordion-react";
import { Button } from "@fremtind/jkl-button-react";
import { TextInput } from "@fremtind/jkl-text-input-react";
import { ToggleSwitch } from "@fremtind/jkl-toggle-switch-react";
import { isValidEpost } from "@fremtind/jkl-validators-util";
import type { ReservationFormData } from "./ReservationFormDigitalCommunication";

export function ReservationDigitalCommunication({initialEmail}: {initialEmail: string}) {
    const {
        register,
        watch,
        setValue,
        formState: { isSubmitting }
    } = useFormContext<ReservationFormData>();

    const reservation = watch("reservation");
 
    const email = watch("email");

     const getErrorMsg = (): string => {
       if (!email) {
              return "E-post mangler, fyll ut mottaker av forsikringsdokumentene.";
        }
        return "Ugyldig e-post";
    };

    
    return (
        <section data-testid="samtykker__ekunde-section">
            <Typography className="mb-12" variant="heading-4">{!reservation ?  "Forsikringdokumentene sendes til" : "Forsikringsdokumenter sendes i posten"} </Typography>
                {!reservation  &&
                <div className="max-w-[474px] mb-40">
                <TextInput
                defaultValue={initialEmail}
                            autoComplete="email"
                            label="Forsikringdokumentene sendes til"
                            labelProps={{srOnly: true}}
                            {...register("email", {
                                required: "Du må fylle inn e-post",
                                validate: (value) => (isValidEpost(value) ? true : getErrorMsg())
                            })}
                            helpLabel={"Mottaker av forsikringsdokumentene."}   
                            errorLabel={!isValidEpost(email) ? getErrorMsg() : ""}
                            className="samtykke__epost"
                            data-testautoid="samtykke-input-epost"
                            data-testid="samtykke-input-epost"
                            width="100%"
                            onKeyDown={(e) => {
                                if (e.key === "Enter") {
                                    e.preventDefault();
                                }
                            }}
                            
                        />
  
             {isValidEpost(email) &&  email !== initialEmail &&
             <Button
                                        className="mt-24"
                                        data-testautoid="samtykke-button-epost"
                                        variant="primary"
                                        onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                                            if (!isValidEpost(email)) {
        
                                                e.preventDefault();
                                                return;
                                          }
        
                                          setValue("email", email);
                                        }}
                                        loader={{ showLoader: isSubmitting, textDescription: "Oppdaterer samtykke" }}
                                        type={"submit"}
                                    >
                                       Lagre
                                    </Button>

            }
 
            </div>
            }
                {!reservation ? 
                <Typography>
                     Hvis du vil motta forsikringsdokumentene i posten må du reservere deg mot digital kommunikasjon.
                </Typography>
                :
                 <Typography variant="body">Hvis du vil motta forsikringsdokumentene digitalt må du fjerne reservasjonen for digital kommunikasjon.</Typography>
             }


            <div className="mt-24" >
                <ToggleSwitch
                    aria-pressed={reservation}
                    {...register("reservation")}
                    onChange={(e, pressed) => {
                        setValue("reservation", pressed);
                    }}
                    data-testid="samtykke-toggle-ekunde"
                    data-testautoid="samtykke-toggle-ekunde"
                >
                    Jeg vil reservere meg og få dokumentene i posten
                </ToggleSwitch>
            </div>

            <Accordion className="mt-64">
                <AccordionItem
                    title="Hva betyr det å motta forsikringsdokumentene digitalt?"
                    data-testid="expander-digital-communication"
                >
                    Du blir varslet når et nytt dokument ligger klart i
                    kundeløsningen. Noen dokumenter kan likevel bli sendt i posten, da blir det ikke sendt et varsel.
                    Elektronisk kommunikasjon er likestilt med brevpost.
                </AccordionItem>
            </Accordion>
        </section>
    );
}
