import { useState } from "react";

import { differenceInCalendarDays } from "date-fns";
import { Trackingkey, track } from "src/tracking";

import { Tab, TabList, TabPanel, Tabs } from "@fremtind/jkl-tabs-react";

import { asDate } from "../../../../common/formatting";
import { Typography } from "../../../../components/Typography";
import { InvoiceOverviewFaq, InvoiceOverviewTable, InvoiceReminder, UnpaidInvoiceOverview } from "../../components";
import { sortInvoiceReminderByDueDate } from "../../components/InvoiceReminder/utils";
import { useInvoiceList } from "../../invoice.queries";
import "./InvoiceOverview.scss";
import { TabType } from "./model";

export const InvoiceOverviewComposer = () => {
    const { data } = useInvoiceList();
    const [activeTab, setActiveTab] = useState(TabType.FORENING_FORBUND);

    const invoicesWithSum = (data?.entity ?? []).filter((invoice) => invoice.belop !== 0);

    const showBedriftForeningFilter =
        invoicesWithSum.some((i) => i.avtaleOrganisasjonsType === "BEDRIFT") &&
        invoicesWithSum.some((i) => i.avtaleOrganisasjonsType === "FORENING_FORBUND");

    const invoicesToDisplay = !showBedriftForeningFilter
        ? invoicesWithSum
        : invoicesWithSum.filter((i) => {
              if (activeTab === TabType.FORENING_FORBUND) {
                  return i.avtaleOrganisasjonsType === "FORENING_FORBUND";
              } else {
                  return i.avtaleOrganisasjonsType === "BEDRIFT";
              }
          });

    const unpaidInvoices = invoicesToDisplay
        .filter((invoice) => invoice.open && invoice.invoiceEventType !== "KREDITNOTA")
        .map((i) => ({ ...i, daysPastDue: differenceInCalendarDays(new Date(), asDate(i.betalingsFrist!)) }))
        .sort(sortInvoiceReminderByDueDate);

    return (
        <>
            <InvoiceReminder />
            {showBedriftForeningFilter && (
                <Tabs
                    className="jkl-spacing-64--bottom"
                    onChange={(e) => {
                        setActiveTab(e as TabType);
                        track({
                            hendelse: Trackingkey.Tab,
                            tab: ["Forening og forbund", "bedrift"][e],
                            tilgjengeligeTabs: ["Forening og forbund", "bedrift"]
                        });
                    }}
                >
                    <TabList>
                        <Tab>Forening og forbund</Tab>
                        <Tab>Bedrift</Tab>
                    </TabList>
                    <TabPanel>
                        <Typography className="jkl-spacing-16--top max-w-[55ch]">
                            Her ser du fakturaene for de forsikringene som dekker medlemmene dine.
                        </Typography>
                    </TabPanel>
                    <TabPanel>
                        <Typography className="jkl-spacing-16--top max-w-[55ch]">
                            Her ser du fakturaene for de forsikringene som dekker noe annet enn medlemmene dine.
                        </Typography>
                    </TabPanel>
                </Tabs>
            )}
            <div className="bm-invoice-overview__heading">
                <UnpaidInvoiceOverview unpaidInvoices={unpaidInvoices} />
                <InvoiceOverviewFaq />
            </div>
            <InvoiceOverviewTable invoices={invoicesToDisplay} activeTab={activeTab} />
        </>
    );
};
