import { AvtaleProduktID } from "@features/agreements/avtale.model";
import { AgreementOverview, type TaskResponseDto } from "@model/gen";

import { Bruker } from "../model/bruker.typer";
import { Distributor } from "../model/theme.typer";
import { MixpanelBruker } from "./mixpanel";

export interface Tracker {
    init: (user: Bruker, ...args: any) => void;
    track: (trackable: Trackable | Trackable[]) => void;
    trackTime: (key: Trackingkey) => void;
    register: (props: Record<string, any>) => void;
    optIn: (user: MixpanelBruker, distributor: Distributor) => void;
    optOut: () => void;
    useTrackNavigation: (active: boolean) => void;
    ABTest: (testName: string, variantName: string) => void;
    preview: (previewName: string, currentName: string) => void;
}
/**
 * https://confluence.intern.sparebank1.no/x/EIJDSQ
 *
 * For å legge til en ny hendelse:
 *   - Legg til en Trackingkey
 *   - Lag et nytt TrackingkeyHendelseNavn interface
 *   - Legg til interfacet i Hendelser
 */
// tekst-strengen blir synlig i mixpanel

/** TODOS
 * - Fjern side på alle hendelser
 */

export enum Trackingkey {
    None = "none",
    OrganisasjonsBytte = "Bytte organisasjon",
    Knappetrykk = "Knappetrykk",
    LastNed = "Last ned",
    LenkeTrykk = "Lenketrykk",
    Endre = "Endre",
    AvsluttAvtale = "Avslutt avtale",
    Tilgang = "Gi, endre eller fjerne tilgang",
    Sidebytte = "Sidebytte",
    SeAvtaledetaljer = "Se avtaledetaljer",
    SeSkadesak = "Se skadesak",
    SeListe = "Se liste",
    SeFaktura = "Se fakturadetaljer",
    SeReelleRettighetshavere = "Se reelle rettighetshavere",
    Tilbakemelding = "Tilbakemelding feedbackmodul",
    TilbakemeldingScore = "Tilbakemelding score",
    TilbakemeldingJaNei = "Tilbakemelding ja/nei",
    Samtykke = "Gi eller trekke samtykke",
    Skjemaflyt = "Skjemaflyt",
    TredjepartVisning = "Tredjepart/visning",
    TredjepartSok = "Tredjepart/søk",
    TredjepartFjernFlyt = "Tredjepart/fjernflyt",
    FjernFlyt = "Fjernflyt",
    Kopier = "Kopier",
    SeTilbud = "Se tilbud",
    SeTilganger = "Se tilganger",
    Varsel = "Varsel vist til bruker",
    VarselFaktura = "Fakturavarsel",
    Utvid = "Utvid",
    KopierKnapp = "Kopier-knapp",
    Filter = "Filter",
    Tab = "Tab",
    Søk = "Søk",
    Preview = "Prøver nytt design",
    KjopMostPopular = "Kjøp mest populære produkter"
}

interface None {
    hendelse: Trackingkey.None;
}

interface OrganisasjonsBytte {
    hendelse: Trackingkey.OrganisasjonsBytte;
    gammelOrganisasjon: string;
    nyOrganisasjon: string;
    bekreftetRRH?: boolean;
    kanKjopeProdukter: string[];
    avtaler?: string[];
}

interface Knappetrykk {
    hendelse: Trackingkey.Knappetrykk;
    knappeId: string;
    dropdown?: boolean;
    tooltip?: boolean;
}

interface LastNed {
    hendelse: Trackingkey.LastNed;
    enkelt: boolean;
    dokumenttype: "faktura" | string;
}

interface LenkeTrykk {
    hendelse: Trackingkey.LenkeTrykk;
    lenkeId: string;
    type?: "snarvei" | "brødsmulesti" | "forside" | "hovednavigasjon" | "footer" | "body" | "varsel";
    ekstern?: boolean;
    tilgjengeligeValg?: string[];
}

interface Endre {
    hendelse: Trackingkey.Endre;
    type:
        | "endre flåte"
        | "meld ut ansatte"
        | "meld inn ansatte"
        | "endre antall ansatte"
        | "meld inn medlem"
        | "meld ut medlem"
        | "endre sykelønn"
        | "endre avtale"
        | "endre samtykke";
    avtale?: string;
    handling?: string;
    felt?: string;
    tidligereVerdi?: string | number | undefined;
    nyVerdi?: string | number | Date | undefined;
    kommentar?: string;
}

interface AvsluttAvtale {
    hendelse: Trackingkey.AvsluttAvtale;
    avtale: string;
    dato?: Date;
    årsak?: string;
    kommentar?: string;
}

interface Tilgang {
    hendelse: Trackingkey.Tilgang;
    handling: "fjern" | "endre" | "gi";
    tilgang: "person" | "organisasjon";
}

interface Sidebytte {
    hendelse: Trackingkey.Sidebytte;
    fra: string;
    til: string;
}

interface SeAvtaledetaljer {
    hendelse: Trackingkey.SeAvtaledetaljer;
    produkt: string;
    kategori: string;
    kanEndres: boolean;
    startdato: string;
    sluttdato: string;
    objekter: string[];
    coreSystem: string;
    status: string;
    forsikringstype: string;
    nyAvtaleTilgjengelig?: boolean;
    skadesaker: number;
    sistOppdatert: string;
}

interface SeSkadesak {
    hendelse: Trackingkey.SeSkadesak;
    status: string;
    beskrivelse: string;
    produkt: string;
    startet: string;
    tittel: string;
    avtaledetaljer?: string;
}

interface SeListe {
    hendelse: Trackingkey.SeListe;
    type:
        | "ansattliste antall"
        | "ansattliste navn"
        | "medlemmer"
        | "fakturaer"
        | "tilbud"
        | "avtaler"
        | "dokumenter"
        | string;
    antall: number;
    liste?: string[];
}

interface SeTilbud {
    hendelse: Trackingkey.SeTilbud;
    eksisterendeAvtaler?: AgreementOverview["productCode"][];
    produkt?: string;
    organisasjon: string;
}

interface SeTilganger {
    hendelse: Trackingkey.SeTilganger;
    type: "egen organisasjon" | "annen organisasjon";
    personer: number;
    organisasjoner: number;
}

interface SeReelleRettighetshavere {
    hendelse: Trackingkey.SeReelleRettighetshavere;
    antallTotalt: number;
    antallAutomatiske: number;
}

// TODO: Kombiner til å være bare TilbakemeldingScore og TilbakemeldingJaNei
interface TilbakemeldingScore {
    hendelse: Trackingkey.TilbakemeldingScore;
    spørsmål: string;
    score: number;
    organisasjon: string;
    kommentar?: string;
}

interface TilbakemeldingJaNei {
    hendelse: Trackingkey.TilbakemeldingJaNei;
    spørsmål: string;
    svar: "Ja" | "Nei" | string;
    kommentar?: string;
}

interface Tilbakemelding {
    hendelse: Trackingkey.Tilbakemelding;
    "Modul tittel": string; // Hvilken modul tilbakemeldingen kommer fra
    spørsmålstype: "radio" | "smiley" | "checkbox" | "text";
    spørsmål: string;
    svar: string | string[] | number | number[];
    verdi?: number | number[] | string | string[];
    kommentar?: string;
}

// TODO: Gjør mer generell
interface Samtykke {
    hendelse: Trackingkey.Samtykke;
    ekunde?: "Ja" | "Nei" | "Blank";
    delingAvData?: "Ja" | "Nei" | "Blank";
}

// TODO: Legge til antall felter
interface SkjemaflytStandard {
    hendelse: Trackingkey.Skjemaflyt;
    flytnavn: string;
    stegnavn: "info" | "kvittering" | "oppsummering" | "endring" | string;
    handling: "neste" | "tilbake" | "avbryt" | "restart" | "ferdig" | "start";
    programmatisk?: boolean;
    avtale?: string;
}

// TODO: Kombiner med SkjemaflytStandard
interface SkjemaflytFerdigRestart {
    hendelse: Trackingkey.Skjemaflyt;
    handling: "ferdig";
    flytnavn: string;
    stegnavn: string;
    programmatisk: boolean;
    omstart: boolean;
}

type TredjepartVisninger = "virksomheter" | "virksomhetsansvarlige";

interface TredjepartVisning {
    hendelse: Trackingkey.TredjepartVisning;
    fraVisning: TredjepartVisninger;
    tilVisning: TredjepartVisninger;
}

interface TredjepartSok {
    hendelse: Trackingkey.TredjepartSok;
    visning: TredjepartVisninger;
    treff: number;
}

export type FjernFlytNavn = "tredjepart/bedriftsansvarlig" | "tredjepart/bedrift" | "sykelønn/person";

interface FjernFlyt {
    hendelse: Trackingkey.FjernFlyt;
    handling: "start" | "bekreft" | "avbryt";
    type: FjernFlytNavn;
}

interface TredjepartFjernFlyt {
    hendelse: Trackingkey.TredjepartFjernFlyt;
    handling: "start" | "bekreft" | "avbryt";
    type: "virksomhetsansvarlig" | "virksomhet";
}

interface Kopier {
    hendelse: Trackingkey.Kopier;
    tekst: string;
    "dom-node": string;
    side: string;
}

type Tredjepart = TredjepartVisning | TredjepartSok | TredjepartFjernFlyt;
type Skjemaflyt = SkjemaflytStandard | SkjemaflytFerdigRestart;

// TODO: Erstatt med Varsel
export interface VarselFaktura {
    hendelse: Trackingkey.VarselFaktura;
    kategori: "faktura";
    type: "forfalt_faktura" | "purring" | "purring_passert_forfallsdato" | "inkassovarsel";
    variant: "info" | "success" | "warning" | "error";
    handling: "open" | "close";
}

export interface Varsel extends TaskResponseDto {
    hendelse: Trackingkey.Varsel;
    global?: boolean;
    type: "faktura" | "nedetid" | "fornyelse" | "årsavslutning" | TaskResponseDto["category"] | string;
    variant: "info" | "success" | "warning" | "error" | "neutral";
    tittel?: TaskResponseDto["title"];
    description?: TaskResponseDto["description"];
    url?: TaskResponseDto["url"];
    status?: TaskResponseDto["status"];
    createdDate?: TaskResponseDto["createdDate"];
    subjects?: TaskResponseDto["subjects"];
    products?: TaskResponseDto["products"];
    category?: TaskResponseDto["category"];
    priority?: TaskResponseDto["priority"];
    productCode?: TaskResponseDto["productCode"];
    agreementNumber?: TaskResponseDto["agreementNumber"];
    role?: TaskResponseDto["role"];
}

interface Utvid {
    hendelse: Trackingkey.Utvid;
    id: string;
    kategori?: string;
}

interface SeFakturadetaljer {
    hendelse: Trackingkey.SeFaktura;
    fakturanummer: string;
    betalingsfrist?: string;
    betalingstype?: string;
    type: string;
    status: string;
    harDokumenter?: boolean;
    coreSystem: string;
}

interface KopierKnapp {
    hendelse: Trackingkey.KopierKnapp;
    tekst: string;
    side: string;
    knappeId: string;
}

// TODO: Fjern side
interface Filter {
    hendelse: Trackingkey.Filter;
    label: string;
    valg: string;
    side?: string;
    tilgjengeligeValg?: Array<string>;
}

interface Tab {
    hendelse: Trackingkey.Tab;
    tab: string;
    tilgjengeligeTabs?: Array<string>;
}

interface Søk {
    hendelse: Trackingkey.Søk;
    tekst: string;
    tekstlengde: number;
    treff: number;
}

interface Preview {
    hendelse: Trackingkey.Preview;
    funksjon: string;
}

interface KjopMostPopular {
    hendelse: Trackingkey.KjopMostPopular;
    produkt: AvtaleProduktID;
    element: "ACCORDION" | "BESTILL_KNAPP" | "LES_MER_KNAPP";
}

export type Trackable =
    | None
    | OrganisasjonsBytte
    | Knappetrykk
    | LastNed
    | LenkeTrykk
    | Endre
    | AvsluttAvtale
    | Tilgang
    | Sidebytte
    | SeAvtaledetaljer
    | SeFakturadetaljer
    | SeSkadesak
    | SeTilbud
    | SeListe
    | SeTilganger
    | SeReelleRettighetshavere
    | Tilbakemelding
    | TilbakemeldingScore
    | TilbakemeldingJaNei
    | Samtykke
    | Utvid
    | Skjemaflyt
    | Tredjepart
    | FjernFlyt
    | Kopier
    | Varsel
    | VarselFaktura
    | KopierKnapp
    | Filter
    | Tab
    | Søk
    | Preview
    | KjopMostPopular;
