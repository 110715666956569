import { type FC } from "react";

import { Link } from "react-router-dom";

import { Typography } from "@components/Typography";
import type { GrunndataContactInfoDto } from "@model/gen";

import { GrunndataGoldenRecordsList } from "./GrunndataGoldenRecordsList";

interface Props {
    goldenRecords: GrunndataContactInfoDto["goldenRecords"];
    error?: GrunndataContactInfoDto["klientFeilmeldinger"];
}

export const GrunndataGoldenRecordsSection: FC<Props> = ({ goldenRecords, error }: Props) => {
    return (
        <section className="golden-records">
            <header className="golden-records__header">
                <Typography variant="heading-2" className="golden-records__header__title">
                    Kontakter
                </Typography>
                <Typography>
                    Informasjonen blir brukt når Fremtind trenger å komme i kontakt med noen fra din virksomhet. Vil du
                    legge til eller gjøre endringer må du{" "}
                    <Link className="jkl-link" to={"/kontakt-oss"}>
                        kontakte oss
                    </Link>
                    .
                </Typography>
            </header>
            <GrunndataGoldenRecordsList goldenRecords={goldenRecords} error={error} />
        </section>
    );
};
