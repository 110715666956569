import { BMRoute } from "src/sider";

import { ProtectedRoute } from "@components/ProtectedRoute";

import Consent from "./views/Consent";

export const reservationRoutes: BMRoute[] = [
    {
        path: "deling-av-data",
        children: [
            {
                element: (
                    <ProtectedRoute>
                        <Consent />
                    </ProtectedRoute>
                ),
                breadcrumbLabel: "Deling av data",
                index: true
            }
        ]
    }
];
