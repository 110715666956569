import { useContext } from "react";
import ThemeContext from "src/context/ThemeContext";
import { Typography } from "@components/Typography";
import { useBruker, useIsManaged } from "@features/bruker/queries";
import "./GrunndataDocumentsSection.scss";
import { isRadgiver } from "src/common/rolle";
import { Flag, useFeatureflag } from "src/common/featureflag";
import { ReservationFormDigitalCommunication }  from "../ReservationDigitalCommunication/ReservationFormDigitalCommunication";
import { ReservationFormDigitalCommunicationNew }  from "../ReservationDigitalCommunication/ReservationFormDigitalCommunication_NEW";
import { useDigitalCommunication, useReservationAndConsent } from "./GrunndataDocumentsSection.queries";


export const GrunndataDocumentsSection =  () => {
    const { distributor } = useContext(ThemeContext);
    const { bruker } = useBruker();
    const isManaged = useIsManaged();
    const digitalReservationResponse = useReservationAndConsent().data; 
    const digitalReservationResponse_NEW = useDigitalCommunication().reservation?.data;

    const shouldUseNewService = useFeatureflag(Flag.NEW_CONSENT);
    const reservation = shouldUseNewService ? (digitalReservationResponse_NEW?.reservation) : (digitalReservationResponse?.consent?.reservation);
    const email =  shouldUseNewService ? (digitalReservationResponse_NEW?.email) || ""  : (digitalReservationResponse?.consent?.email) || "";

    if (distributor === "dnb" || isManaged || reservation === undefined  ) return null;
        return (
            <section className="grunndata-documents-reciever">
                <header>
                     <Typography variant="heading-2">Mottaker av forsikringsdokumenter</Typography>
                </header>
                <section className="bg-hvit max-w-[672px]">

                {shouldUseNewService &&  
                <ReservationFormDigitalCommunicationNew
                    isRadgiver={isRadgiver(bruker)}
                    isDNB={true}
                    formInfo={{ reservation, email }}
                    />
                    }

                 {!shouldUseNewService  &&
                <ReservationFormDigitalCommunication
                    isRadgiver={isRadgiver(bruker)}
                    isDNB={false}
                    formInfo={{ reservation, email }}
                 />
                    }
            </section>
            </section>
        );
    
};
