import { useEffect, useRef } from "react";


// En hook som ved endring returnerer forrige verdi
export const useForrigeVerdi = <T>(verdi: T): T | undefined => {
    const ref = useRef<T>();

    useEffect(() => {
        ref.current = verdi;
    });

    return ref.current;
};

