import {  useEffect } from "react";
import { Trackingkey, track } from "src/tracking";
import { Side, SideHeader, SideInnhold } from "@components/Side";
import { ErrorMessageBox } from "@fremtind/jkl-message-box-react";
import { GrunndataDocumentsSection, GrunndataGoldenRecordsSection } from "../components";
import {  useGetGrunndataContactInfo } from "../grunndataContactInfo.queries";
import "./GrunndataContactInfoView.scss";


export const GrunndataContactInfoView = () => {
    const grunndata = useGetGrunndataContactInfo();
    const grunndataGoldenRecords = grunndata.data?.goldenRecords;

    useEffect(() => {
        track({
            hendelse: Trackingkey.SeListe,
            type: "Golden Records (organisasjoner)",
            antall: grunndataGoldenRecords?.length ?? 0
        });
    }, [grunndataGoldenRecords]);


    return (
    <Side>
                <>
                <SideHeader title="Kontakter" lead="Her kan du endre hvem som skal stå som mottaker av forsikringsdokumentene og om du vil motta de digitalt eller i posten. Du kan også se kontaktene som står oppført for din virksomhet." />
                <SideInnhold
                    loadingText="Laster inn kontaktpersonene dine"
                    className="grunndata-contact-view"
                    query={grunndata}
                >
                    <GrunndataDocumentsSection/>
                    {grunndata.isError && <ErrorMessageBox>Klarte ikke hente samtykke</ErrorMessageBox>}
                    {grunndata.data  && (
                      
                            <GrunndataGoldenRecordsSection
                                goldenRecords={grunndataGoldenRecords}
                                error={grunndata.data?.klientFeilmeldinger}
                            />
                 
                    )}
                </SideInnhold>
                </>
        </Side>

    );
};
