import { useRef, useState } from "react";

import { ApplicationName, DomainName, ProcessName } from "common";
import { useForm } from "react-hook-form";
import { useParams } from "react-router";
import { Trackingkey, track } from "src/tracking";

import { SkjemaFlyt, Steg, StegProps } from "@components/SkjemaFlyt";
import {
    AgreementUpdateRequests,
    useRequestUpdateAgreement
} from "@features/agreement-updates/agreementupdates.queries";
import {
    MILEAGE_FIELD_NAME,
    SUCCESSFULL_UPDATE_CODE,
    findField
} from "@features/agreement-updates/agreementupdates.utils";
import { useAgreementDetails } from "@features/agreements/agreements.queries";
import { useBruker } from "@features/bruker/queries";
import { AgreementUpdatableField, AgreementUpdateResponse } from "@model/gen";
import { UseMutationResult } from "@tanstack/react-query";

import { Receipt, UpdateMileage } from "./steps";

export type AgreementUpdateStep = StegProps & {
    agreementId: string;
    updatableFields: AgreementUpdatableField[];
    initialValues: {
        mileage: string;
    };
    agreementUpdateMutation: UseMutationResult<AgreementUpdateResponse, Error, AgreementUpdateRequests, unknown>;
    onSubmit: (onFrem: (programmatisk: boolean) => void) => void;
    isMileageUpdatable: boolean;
    hasUpdatedMileage: boolean;
    error: string | null;
    hasSuccessFullyUpdated: boolean;
};

export type MileageInputs = {
    mileage: string;
};

const mileageSteps: Steg<AgreementUpdateStep>[] = [
    {
        stegnavn: "oppdater-kmstand",
        element: (props) => <UpdateMileage {...props} />
    },
    {
        stegnavn: "kvittering",
        element: (props) => <Receipt {...props} />
    }
];

export const Mileage: React.FC<{
    updatableFields: AgreementUpdatableField[];
}> = ({ updatableFields }) => {
    const { agreementId } = useParams();
    const { valgtOrganisasjon } = useBruker();
    const { data: agreement } = useAgreementDetails(agreementId || "");
    const productCode = agreement?.productCode;

    const mileageField = findField(updatableFields, MILEAGE_FIELD_NAME);
    const agreementUpdateMutation = useRequestUpdateAgreement();
    const { mutate } = agreementUpdateMutation;

    const currentMileage = mileageField?.selected?.valueId || "";
    const mileageRef = useRef(currentMileage);

    const formMethods = useForm<MileageInputs>({
        defaultValues: {
            mileage: currentMileage || ""
        }
    });

    const isMileageUpdatable = !!mileageField;

    const mileage = formMethods.watch("mileage");
    const hasUpdatedMileage = mileage !== currentMileage;

    const [error, setError] = useState<string | null>(null);
    const [hasSuccessFullyUpdated, setHasSuccessFullyUpdated] = useState(false);

    const isMileageLowerThanInitial = parseInt(mileage || "0") < parseInt(currentMileage || "0");

    const trackingValues = { mileage: formMethods.getValues("mileage") };

    const onSubmit = (onFrem: (programmatisk: boolean) => void) => {
        if (!hasUpdatedMileage) return;

        if (isMileageLowerThanInitial) {
            formMethods.setError("mileage", {
                type: "custom",
                message: "Kilometerstand kan ikke være lavere enn dagens oppgitte kilometerstand."
            });
            return;
        }

        formMethods.clearErrors();
        setError(null);

        const updatableFields: AgreementUpdatableField[] = [
            {
                fieldId: mileageField?.fieldId,
                // @ts-ignore Resten av feltene er ikke i bruk
                selected: {
                    valueId: mileage ?? ""
                }
            }
        ];

        mutate(
            {
                id: agreementId ?? "",
                organisationNumber: valgtOrganisasjon?.organisasjonsnummer ?? "",
                payload: {
                    id: agreementId,
                    confirmed: true,
                    updatableFields
                }
            },
            {
                onSuccess: (res) => {
                    const { success, errorCodes, infoCodes } = res;
                    if (!success) {
                        const errorMessage =
                            "Det har skjedd en feil under oppdatering av avtalen, og endringen ble ikke lagret. Prøv på nytt eller kontakt rådgiver";

                        if (errorCodes && errorCodes.length > 0) {
                            setError(errorMessage);
                        }
                        return;
                    }

                    if (infoCodes?.some((code) => code === SUCCESSFULL_UPDATE_CODE)) {
                        setHasSuccessFullyUpdated(true);
                        track({
                            hendelse: Trackingkey.Endre,
                            type: "endre avtale",
                            avtale: productCode,
                            tidligereVerdi: mileageRef.current,
                            nyVerdi: mileage,
                            felt: "kilometerstand"
                        });

                        onFrem(true);
                    }
                }
            }
        );
    };

    return (
        <section className="update-insurance__form">
            <SkjemaFlyt
                tracking={{
                    domain: DomainName.Insurance,
                    process: ProcessName.EditInsurance,
                    application: ApplicationName.EditCarMilage,
                    applicationValues: trackingValues
                }}
                formMethods={formMethods}
                flytnavn="Oppdater kilometerstand"
                stegListe={mileageSteps}
                {...{
                    agreementId,
                    updatableFields,
                    initialValues: {
                        mileage: mileageRef.current || ""
                    },
                    agreementUpdateMutation,
                    onSubmit,
                    isMileageUpdatable,
                    hasUpdatedMileage,
                    error,
                    hasSuccessFullyUpdated
                }}
            />
        </section>
    );
};
