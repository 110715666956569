import React, { ReactNode, useEffect, useState } from "react";

import classNames from "classnames";
import { useLocation, useNavigate } from "react-router-dom";

import { Typography } from "@components/Typography";
import { localSystemTask } from "@features/tasks/components/TaskList/LocalTasks";
import { useTasks } from "@features/tasks/tasks.queries";
import { InfoMessage } from "@fremtind/jkl-message-react";

import { useBruker } from "../../features/bruker/queries";
import "./Side.scss";

const onlyDefined = (props: Record<string, any>) =>
    Object.fromEntries(Object.entries(props).filter(([key, value]) => key !== "children" && value !== undefined));

interface Props {
    indented?: boolean;
    compact?: boolean;
    compactTextOnly?: boolean;
    children: ReactNode;
    className?: string;
    hideSystemAlert?: boolean;
}
export const Side = (props: Props) => {
    const navigate = useNavigate();
    const location = useLocation();
    const { bruker } = useBruker();

    useEffect(() => {
        if (!bruker?.loggetInn && !["loggut", "feil", "bytt-konsesjon"].some((l) => location.pathname.includes(l))) {
            navigate("/loggut");
        }
    }, [bruker, location.pathname, navigate]);

    const classes = classNames("side", props.className);
    const tasks = useTasks();
    const systemTask =
        tasks.data?.find((task) => task.category?.toLowerCase() === "system") ||
        (localSystemTask?.shouldShow && localSystemTask);

    const [dismissed, setDismissed] = useState<boolean>(
        sessionStorage.getItem("bm-sysmtem-alert-dismissed") ? true : false
    );

    const handleDismiss = () => {
        sessionStorage.setItem("bm-sysmtem-alert-dismissed", "true");
        setDismissed(true);
    };

    return (
        <div className={classes}>
            {systemTask && !props.hideSystemAlert && !dismissed && (
                <InfoMessage
                    title={systemTask.title}
                    fullWidth={true}
                    dismissed={dismissed}
                    dismissAction={{ handleDismiss: () => handleDismiss() }}
                >
                    {systemTask.description && <Typography>{systemTask.description}</Typography>}
                </InfoMessage>
            )}
            {React.Children.map(props.children, (child) => {
                if (!React.isValidElement(child)) return;

                return React.cloneElement(child, onlyDefined(props));
            })}
        </div>
    );
};
