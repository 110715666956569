import { VoidFunctionComponent, useCallback, useEffect, useMemo, useState } from "react";

import { ApplicationName, DomainName, EventName, ProcessName, trackEvent } from "common";
import { FormProvider, UseFormReturn } from "react-hook-form";

import { Trackingkey, track } from "../../tracking";

export interface StegProps {
    onTilbake: (programmatisk?: boolean, steg?: number) => void;
    onFrem: (programmatisk?: boolean) => void;
    onFerdig: (restart?: boolean, programmatisk?: boolean) => void;
    onAvbryt: (programmatisk?: boolean) => void;
    onRestart: (programmatisk?: boolean) => void;
}
export interface Steg<T = Record<string, unknown> & StegProps> {
    stegnavn: string;
    element: VoidFunctionComponent<T>;
}

export interface FormTrackingMeta {
    flowName: string;
    stepName: string;
}

export interface SkjemaFlytProps<T extends StegProps> {
    flytnavn: string;
    stegListe: Steg<T>[];
    formMethods: UseFormReturn<any>;
    setFormTrackingMeta?: (arg0: FormTrackingMeta) => void;
    tracking?: {
        domain: DomainName;
        process: ProcessName;
        application: ApplicationName;
        applicationValues: object;
    };
}

const isOutOfBounds = (newIndex: number, length: number) => {
    if (length - (newIndex + 1) < 0) {
        return true;
    }

    if (newIndex + 1 > length) {
        return true;
    }

    return false;
};

const useFlythandling = (flytnavn: string, stegnavn: string) => {
    const fh = useCallback(
        (handling: "neste" | "tilbake" | "ferdig" | "avbryt" | "restart", programmatisk = false, omstart = false) => {
            if (handling === "ferdig") {
                track({
                    hendelse: Trackingkey.Skjemaflyt,
                    flytnavn,
                    stegnavn,
                    handling,
                    programmatisk,
                    omstart
                });
            } else {
                track({
                    hendelse: Trackingkey.Skjemaflyt,
                    flytnavn,
                    stegnavn,
                    handling
                });
            }
        },
        [flytnavn, stegnavn]
    );

    return fh;
};

export function SkjemaFlyt<T extends StegProps>({
    stegListe,
    formMethods,
    flytnavn,
    setFormTrackingMeta,
    tracking,
    ...rest
}: SkjemaFlytProps<T>) {
    const [currentSteg, setCurrentSteg] = useState(0);
    const currentStegObj = useMemo(() => stegListe[currentSteg], [currentSteg, stegListe]);
    const flytHandling = useFlythandling(flytnavn, currentStegObj.stegnavn);

    useEffect(() => {
        window.scroll(0, 0);
    }, [currentSteg]);

    useEffect(() => {
        if (setFormTrackingMeta) {
            setFormTrackingMeta({ flowName: flytnavn, stepName: currentStegObj.stegnavn });
        }
    }, [currentStegObj, flytnavn, setFormTrackingMeta]);

    const onFrem: StegProps["onFrem"] = useCallback(
        (programmatisk = false) => {
            if (tracking) {
                trackEvent({
                    ...tracking,
                    event: EventName.FillApplication,
                    applicationLength: stegListe.length,
                    stepName: stegListe[currentSteg].stegnavn,
                    stepNumber: currentSteg + 1,
                    action: "Neste"
                });
            }
            const nextSteg = currentSteg + 1;

            if (isOutOfBounds(nextSteg, stegListe.length)) {
                return;
            }

            flytHandling("neste", programmatisk);
            setCurrentSteg(nextSteg);
        },
        [tracking, currentSteg, flytHandling, stegListe]
    );

    const onTilbake: StegProps["onTilbake"] = useCallback(
        (programmatisk = false, steg?: number) => {
            if (tracking) {
                trackEvent({
                    ...tracking,
                    event: EventName.FillApplication,
                    applicationLength: stegListe.length,
                    stepName: stegListe[currentSteg].stegnavn,
                    stepNumber: currentSteg + 1,
                    action: "Tilbake"
                });
            }
            const nextSteg = steg !== undefined ? steg : currentSteg - 1;

            if (isOutOfBounds(nextSteg, stegListe.length)) {
                return;
            }

            flytHandling("tilbake", programmatisk);
            setCurrentSteg(nextSteg);
        },
        [currentSteg, flytHandling, stegListe, tracking]
    );

    const onFerdig: StegProps["onFerdig"] = useCallback(
        (restart = false, programmatisk = false) => {
            if (tracking) {
                trackEvent({
                    ...tracking,
                    event: EventName.FillApplication,
                    applicationLength: stegListe.length,
                    stepName: stegListe[currentSteg].stegnavn,
                    stepNumber: currentSteg + 1,
                    action: "Send"
                });
            }
            flytHandling("ferdig", programmatisk, restart);

            if (restart) {
                formMethods.reset();
                setCurrentSteg(0);
            }
        },
        [tracking, currentSteg, flytHandling, formMethods, stegListe]
    );

    const onAvbryt: StegProps["onAvbryt"] = useCallback(
        (programmatisk = false) => {
            if (tracking) {
                trackEvent({
                    ...tracking,
                    event: EventName.FillApplication,
                    applicationLength: stegListe.length,
                    stepName: stegListe[currentSteg].stegnavn,
                    stepNumber: currentSteg + 1,
                    action: "Avslutt"
                });
            }
            flytHandling("avbryt", programmatisk);
        },
        [currentSteg, flytHandling, stegListe, tracking]
    );

    const onRestart: StegProps["onRestart"] = useCallback(
        (programmatisk = false) => {
            if (tracking) {
                trackEvent({
                    ...tracking,
                    event: EventName.FillApplication,
                    applicationLength: stegListe.length,
                    stepName: stegListe[currentSteg].stegnavn,
                    stepNumber: currentSteg + 1,
                    action: "Restart"
                });
            }
            flytHandling("restart", programmatisk);
            setCurrentSteg(0);
            formMethods.reset();
        },
        [currentSteg, flytHandling, formMethods, stegListe, tracking]
    );

    return (
        <FormProvider {...formMethods}>
            {/* @ts-ignore Vi bryr oss ikke om hvilket spesifikke element som blir brukt her. */}
            <currentStegObj.element
                onFrem={onFrem}
                onTilbake={onTilbake}
                onAvbryt={onAvbryt}
                onFerdig={onFerdig}
                onRestart={onRestart}
                {...rest}
            />
        </FormProvider>
    );
}
